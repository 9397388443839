import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addDays,
} from "date-fns";
import { Select } from "antd";
import moment from "moment";

const CustomDateRangePicker = ({
  dateTypes,
  setStartDate,
  setEndDate,
  setDisabledDate,
}) => {
  const [range, setRange] = useState({ value: "Today", label: "Today" });
  const handleRangeChange = (selectedRange) => {
    const today = moment().toDate();
    const tomorrow = addDays(today, 1);
    setRange(selectedRange);
    switch (selectedRange) {
      case "Today":
        setStartDate(today);
        setEndDate(today);
        setDisabledDate(true);
        break;
      case "Yesterday":
        setStartDate(subDays(today, 1));
        setEndDate(subDays(today, 1));
        setDisabledDate(true);
        break;
      case "LastTwoDays":
        setStartDate(subDays(today, 1));
        setEndDate(today);
        setDisabledDate(true);
        break;
      case "LastThreeDays":
        setStartDate(subDays(today, 2));
        setEndDate(today);
        setDisabledDate(true);
        break;
      case "LastSevenDays":
        setStartDate(subDays(today, 6));
        setEndDate(today);
        setDisabledDate(true);
        break;
      case "LastTenDays":
        setStartDate(subDays(today, 9));
        setEndDate(today);
        setDisabledDate(true);
        break;
      case "Last15Days":
        setStartDate(subDays(today, 14));
        setEndDate(today);
        setDisabledDate(true);
        break;
      case "CurrentWeek":
        setStartDate(startOfWeek(today));
        setEndDate(endOfWeek(today));
        setDisabledDate(true);
        break;
      case "LastWeek":
        setStartDate(startOfWeek(subDays(today, 6)));
        setEndDate(endOfWeek(subDays(today, 6)));
        setDisabledDate(true);
        break;
      case "CurrentMonth":
        setStartDate(startOfMonth(today));
        setEndDate(endOfMonth(today));
        setDisabledDate(true);
        break;
      case "LastMonth":
        setStartDate(startOfMonth(subDays(today, 29)));
        setEndDate(endOfMonth(subDays(today, 29)));
        setDisabledDate(true);
        break;
      case "Custom":
        setStartDate(null);
        setEndDate(null);
        setDisabledDate(false);
        break;
      default:
        setStartDate(null);
        setEndDate(null);
        setDisabledDate(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center gap-2 w-100">
        <span className="font-style white-space fw-semibold">Date Range :</span>
        <Select
          className="w-100"
          placeholder="Select Date"
          options={dateTypes}
          onChange={handleRangeChange}
          value={range}
        />
      </div>
    </>
  );
};

export default CustomDateRangePicker;

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  LOCALPURCHASEGROCERY: {
    path: `${baseRoutes.userBaseRoutes}local-purchase-grocery`,
  },
  VEG_RECO: {
    path: `${baseRoutes.userBaseRoutes}veg-reco`,
  },
};

export default accessRoute;

import { Outlet } from "react-router-dom";
import { baseRoutes } from "../../../helpers/baseRoutes";
import routesMap from "../../../routeControl/userRouteMap";
import {
  LocalPurchaseGrocery,
  VegLocalPurchase,
  VegReco,
} from "../../../pages";

export default function route() {
  return [
    {
      path: baseRoutes.userBaseRoutes,
      name: "Transactions",
      key: baseRoutes.userBaseRoutes,
      commonRoute: true,
      belongsToSidebar: true,
      private: true,
      element: <Outlet />,
      children: [
        {
          path: routesMap.VEGLOCALPURCHASE.path,
          name: "Veg Local Purchase",
          key: routesMap.VEGLOCALPURCHASE.path,
          belongsToSidebar: true,
          commonRoute: true,
          private: true,
          element: <VegLocalPurchase />,
        },
        {
          path: routesMap.LOCALPURCHASEGROCERY.path,
          name: "Local Purchase Grocery",
          key: routesMap.LOCALPURCHASEGROCERY.path,
          belongsToSidebar: true,
          commonRoute: true,
          private: true,
          element: <LocalPurchaseGrocery />,
        },
        {
          path: routesMap.VEG_RECO.path,
          name: "Veg Reco",
          key: routesMap.VEG_RECO.path,
          belongsToSidebar: false,
          commonRoute: true,
          private: true,
          element: <VegReco />,
        },
      ],
    },
  ];
}

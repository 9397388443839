import React from "react";

export * from "./Store/index";
export const NotFound = React.lazy(() => import("./NotFound/index"));
export const NoInternetConnection = React.lazy(() =>
  import("./NoInternetConnection/index")
);
export const PublicAnalysisReport = React.lazy(() =>
  import("./WebViewPages/AnalysisReports/index")
);

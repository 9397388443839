import { UserLogin, SignupUser, PublicAnalysisReport } from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.LOGIN.path,
      name: "",
      key: routesMap.LOGIN.path,
      commonRoute: true,
      private: false,
      element: <UserLogin />,
    },

    {
      path: routesMap.SIGNUP.path,
      name: "",
      key: routesMap.SIGNUP.path,
      commonRoute: true,
      private: false,
      element: <SignupUser />,
    },
    {
      path: routesMap.TEST.path,
      name: "",
      key: routesMap.TEST.path,
      commonRoute: true,
      private: false,
      element: <PublicAnalysisReport />,
    },
  ];
}
